import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col, Hidden } from "react-awesome-styled-grid"
import { Heading2 } from "../../components/Heading"
import Layout from "../../components/Layout"
import Block from "../../components/Block"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import DivHelper from "../../components/DivHelper"
import { TableOfContents } from "../../components/TableOfContents"

const ProductBox = styled(DivHelper)`
  display: inline-block;
  margin-top: 20px;
  border-radius: 4px;
  border: 2px solid #000;
  padding: 10px 20px;
  text-decoration: underline;
`

const ProductLabel = styled.p`
  text-align: center;
  font-weight: 600;
`

const Background = ({ image, className, children }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const CbdLegalitaet = ({ location, data: { image } }) => {
  return (
    <Layout
      location={location}
      title="CBD kaufen ist legal – das sagen EuGH und BGH dazu"
      mt0
      light
    >
      <Hero heightVh={50}>
        <Background image={image}>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>
                    CBD kaufen ist legal – das sagen EuGH und BGH dazu
                  </HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row>
            <Col>
              <Heading2>Übersicht</Heading2>
              <TableOfContents>
                <li>
                  <a href="#ueberblick">
                    CBD Öl Testsieger schön und gut, aber darf ich das überhaupt
                    kaufen?
                  </a>
                </li>
                <li>
                  <a href="#eugh">
                    Laut EuGH ist CBD zwar von der Hanfpflanze, aber kein
                    Rauschgift
                  </a>
                </li>
                <li>
                  <a href="#bgh">
                    BGH erlaubt CBD nur, wenn der Rausch ausgeschlossen ist
                  </a>
                </li>
                <li>
                  <a href="#bgh-2022">
                    Aktuellste BGH-Entscheidung 2022 zu CBD
                  </a>
                </li>
                <li>
                  <a href="#cbd-legal">
                    CBD ist und bleibt legal, aber darauf ist zu achten
                  </a>
                </li>
              </TableOfContents>
            </Col>
          </Row>
        </Block>
        <Block id="ueberblick">
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2>
                CBD Öl Testsieger schön und gut, aber darf ich das überhaupt
                kaufen?
              </Heading2>
              <p>
                Immer häufiger erhalten wir von unseren Leserinnen und Lesern
                die Rückfrage, ob unsere CBD Produktvergleiche wie das{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl</Link>, die{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>{" "}
                und andere CBD Produkte überhaupt legal seien. Wir finden, die
                Frage ist tatsächlich berechtigt. Und um Klarheit zu schaffen,
                haben wir uns dem Thema angenommen und uns tiefer in die Materie
                und in die <strong>aktuelle Rechtslage</strong> eingearbeitet.
                Schließlich stammt das CBD von der Hanfpflanze und im
                allgemeinen Volksmund ist Hanf erst einmal verboten und damit
                grundsätzlich illegal. Doch ein klares Verständnis über die
                Bestandteile einer Hanfpflanze und deren Wirkeffekte gibt
                Aufschlüsse darüber, welches großes Potenzial in einer
                Hanfpflanze steckt und das nicht alles von ihr in einem Rausch
                endet. Daher ist es besonders wichtig, sich auf{" "}
                <strong>seriöse Quellen</strong> wie Aussagen der{" "}
                <strong>WHO, dem EuGH und dem BGH</strong> zu beziehen, wenn es
                um die Legalität und Gefahreneinstufung von CBD geht.
              </p>
            </Col>
            <Hidden xs sm md>
              <Col xs={12} sm={10} md={6} lg={5}>
                <StaticImage
                  src="../../assets/images/stock/gerechtigkeit.jpg"
                  placeholder="blurred"
                  layout="constrained"
                  width={500}
                  alt="Goldene Statue mit Schwert und Waage in den Händen"
                />
              </Col>
            </Hidden>
          </Row>
        </Block>
        <Block id="eugh">
          <Row align="center" justify="center">
            <Col xs={12} sm={10} md={6} lg={4}>
              <StaticImage
                src="../../assets/images/stock/flagge_europa.jpg"
                placeholder="blurred"
                layout="constrained"
                width={500}
                alt="Europäische Flagge"
              />
            </Col>
            <Col xs={12} lg={8}>
              <Heading2>
                Laut EuGH ist CBD zwar von der Hanfpflanze, aber kein Rauschgift
              </Heading2>
              <p>
                In einem Grundsatzurteil legt das höchste Gericht Europas, der
                EuGH, klipp und klar fest, dass{" "}
                <strong>CBD nicht als Suchtstoff</strong> gesehen wird. Es ist
                somit Fakt, dass{" "}
                <strong>
                  CBD von der Hanfpflanze nicht verboten und illegal
                </strong>{" "}
                ist. Das geht aus dem Urteil{" "}
                <a
                  href="https://curia.europa.eu/juris/document/document.jsf?text=&docid=233925&pageIndex=0&doclang=de&mode=req&dir=&occ=first&part=1&cid=1221974"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ECLI:EU:C:2020:938
                </a>{" "}
                in der Rechtssache C-663/18 vom 19. November 2020 hervor. Der
                EuGH unterscheidet hier ausdrücklich CBD von THC. Zwar ist
                Cannabidiol (CBD) ebenfalls ein Molekül, das in Hanf (oder
                Cannabis sativa) vorhanden ist und auch zur Familie der
                Cannabinoide gehört, aber der EuGH begründet seine Entscheidung
                damit, dass{" "}
                <strong>
                  nach dem gegenwärtigen Stand der wissenschaftlichen
                  Erkenntnisse CBD keine psychotropen Wirkungen oder schädliche
                  Auswirkungen auf die menschliche Gesundheit
                </strong>{" "}
                hat und beruft sich damit auf Aussagen der{" "}
                <strong>
                  <a
                    href="https://www.who.int/docs/default-source/controlled-substances/whocbdreportmay2018-2.pdf?sfvrsn=f78db177_2&download=trueWHO"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    WHO
                  </a>
                </strong>
                : &bdquo;CBD is generally well tolerated with a good safety
                profile.&ldquo; (
                <a
                  href="https://www.who.int/docs/default-source/controlled-substances/whocbdreportmay2018-2.pdf?sfvrsn=f78db177_2&download=trueWHO"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  WHO Critical Review Report 2018, page 6
                </a>
                ). Der aktuelle Wissensstand wurde daher in dem Gerichtsurteil
                vom November 2020 berücksichtigt und für richtig empfunden.
                Anders ist es jedoch beim Tetrahydrocannabinol (THC), ein
                weiteres Cannabinoid der Hanfpflanze, das den gegenwärtig
                bekannten Rausch verursacht.{" "}
                <strong>
                  CBD unterscheidet sich demnach eindeutig von THC
                </strong>
                .
              </p>
            </Col>
          </Row>
        </Block>
        <Block id="bgh">
          <Row align="center" justify="center">
            <Col xs={12} sm={10} md={6} lg={4}>
              <StaticImage
                src="../../assets/images/stock/flagge_deutschland.jpg"
                placeholder="blurred"
                layout="constrained"
                width={500}
                alt="Deutsche Flagge"
              />
            </Col>
            <Col xs={12} lg={8}>
              <Heading2>
                BGH erlaubt CBD nur, wenn der Rausch ausgeschlossen ist
              </Heading2>
              <p>
                Etwas differenzierter als der EuGH ist die Sichtweise des BGHs
                auf die Situation auf deutscher Ebene. Grundsätzlich regelt das
                Betäubungsmittelgesetz, dass{" "}
                <strong>CBD mit einem THC-Wert unter 0,2 % erlaubt</strong> ist.
                Geregelt wird das konkret in Anlage I zu § 1 Abs. 1 des
                Betäubungsmittelgesetzes. Darin wird zunächst aufgeführt, dass
                Cannabis als nicht verkehrsfähiges Betäubungsmittel zu sehen
                ist. In Nummer b) des Gesetzes wird das Verbot allerdings
                zumindest etwas ausgehebelt. Darin heißt es, dass Cannabis legal
                ist, &bdquo;wenn [der] Gehalt an Tetrahydrocannabinol [THC] 0,2
                Prozent nicht übersteigt und der Verkehr mit ihnen (ausgenommen
                der Anbau) ausschließlich gewerblichen oder wissenschaftlichen
                Zwecken dient, die einen Missbrauch zu Rauschzwecken
                ausschließen&ldquo;. Demnach ist es für das Karlsruher Gericht
                maßgeblich, dass die Abgabe von{" "}
                <strong>
                  CBD an Endkunden unter 0,2 % erlaubt ist, aber nicht zu
                  Rauschzwecken dienen darf
                </strong>
                . In einem interessanten Fall beschäftigte sich das BGH circa
                einem Jahr nach dem EuGH Urteil genau mit dieser Thematik am 24.
                März 2021 im Fall{" "}
                <a
                  href="https://www.bundesgerichtshof.de/SharedDocs/Pressemitteilungen/DE/2021/2021066.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  6 StR 240/20
                </a>
                .
              </p>
              <p>
                Konkret ging es um CBD als Gras, das in Form von{" "}
                <strong>Hanftee auf den Markt</strong> gebracht wurde. Der Fall,
                der dem BGH vorlag, ging aus dem Urteil{" "}
                <a
                  href="https://www.rechtsprechung.niedersachsen.de/jportal/portal/page/bsndprod?feed=bsnd-r-og&amp;showdoccase=1&amp;paramfromHL=true&amp;doc.id=JURE200005429"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  4 KLS 804 Js 6499 (18/5/19)
                </a>{" "}
                vom Landgericht Braunschweig vom 28. Januar 2020 voraus. Der
                Sachverständige des BGH stufte den Hanftee als Betäubungsmittel
                und damit als illegal ein, weil der Hanftee zwar nicht beim
                Aufguss mit Wasser, aber{" "}
                <strong>
                  nach der Verarbeitung zu Gebäck einen Rausch hervorrufen kann
                </strong>
                . Infolgedessen urteilte der BGH, dass der Verkauf des Hanftees
                ein Betäubungsmittel sei. Diese Einstufung als Betäubungsmittel
                misst sich an Position &bdquo;Cannabis&ldquo; nach Anlage I zu §
                1 Abs. 1 BtmG (Betäubungsmittelgesetz) und der dort vorgesehenen
                Ausnahme zu Nummer b), weil ein{" "}
                <strong>Missbrauch des Cannabisprodukts zu Berauschung</strong>{" "}
                nicht ausgeschlossen werden konnte.
              </p>
            </Col>
          </Row>
        </Block>
        <Block id="bgh-2022">
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2>Aktuellste BGH-Entscheidung 2022 zu CBD</Heading2>
              <p>
                Etwa 2 Jahre und 5 Monate später am 23. Juni 2022 urteilte der
                BGH wieder und in seinem jüngsten Fall über CBD. In dem Fall{" "}
                <a
                  href="http://juris.bundesgerichtshof.de/cgi-bin/rechtsprechung/document.py?Gericht=bgh&amp;Art=en&amp;az=5%20StR%20490/21&amp;nr=131377"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  5 StR 490/21
                </a>{" "}
                ging es um ein <strong>bandenmäßiges Handelstreiben</strong>,
                bei dem zwei Angeklagte{" "}
                <strong>innerhalb von zwei Monaten 120 kg CBD-Blüten</strong>{" "}
                beschafften und es zumindest vor hatten, an Wiederverkäufer zu
                veräußern. In Laboruntersuchungen wiesen die CBD-Blüten zwar
                einen Wirkstoffgehalt von 0,2 % THC auf und wären damit erst
                einmal legal.{" "}
                <strong>
                  Es fehlte aber an der Voraussetzung, dass ein Missbrauch zu
                  Rauschzwecken ausgeschlossen ist
                </strong>
                . Auch hier argumentierte der BGH wieder damit, dass die Blüten
                beim Backen erhitzt werden, und das führte zur Freisetzung von
                weiterem THC, das beim Konsum durch den Endabnehmer von größeren
                Mengen einen Cannabisrausch erzeugt.
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <StaticImage
                src="../../assets/images/stock/legal.jpg"
                placeholder="blurred"
                layout="constrained"
                width={500}
                alt="Papierfetzen mit der Aufschrift ILLEGAL und LEGAL"
              />
            </Col>
          </Row>
        </Block>
        <Block id="cbd-legal">
          <Row align="center" justify="center">
            <Col md={8} xl={9}>
              <Heading2>
                CBD ist und bleibt legal, aber darauf ist zu achten
              </Heading2>
              <p>
                Liebe Leserinnen und Leser, grundsätzlich ist der Kauf und
                Konsum von{" "}
                <strong>CBD mit einem THC Gehalt von 0,2 % legal</strong> und
                vollkommen erlaubt. Wichtig ist es nur, dass es{" "}
                <strong>
                  in jeglicher Darreichungsform nicht für Rauschzwecke
                </strong>{" "}
                eingesetzt wird, um high zu werden. CBD soll nicht als
                Schlupfloch gesehen und daher nicht für diese Zwecke eingesetzt
                werden. Ein Rausch kann grundsätzlich vermieden werden, wenn die
                Mengen gering sind, z.B. durch Höchstgrenzen beim Gewicht. Die{" "}
                <strong>Regulierung von Abgabemengen</strong>, wie durch
                kleinere Mengen führen dazu, dass{" "}
                <strong>Einzeldosen nicht berauschend</strong> wirken. Zudem
                können <strong>klare Warnhinweise</strong> Verbraucher vor
                missbräuchlichen Zwecken abhalten.
              </p>
              <p>
                Auf der anderen Seite kritisieren wir den BGH, da es oftmals nur
                theoretisch möglich ist, durch CBD einen Rausch herbeizuführen.{" "}
                <strong>
                  In der Praxis ist es nämlich nahezu unmöglich, Unmengen an CBD
                  zu konsumieren
                </strong>
                , um davon high zu werden. Zudem ist es viel{" "}
                <strong>
                  zu teuer, solche großen Mengen an CBD einzukaufen
                </strong>
                . Rechnungen zufolge benötigt man bei einem THC-Gehalt von 0,2 %
                etwa 7,5 Gramm reines CBD Gras um high zu werden. Bei gängigen
                Marktpreisen von durchschnittlich 10,00 € pro 1 Gramm CBD
                entspreche dies einem Gesamtpreis von 75,00 €, um high zu
                werden. Ein hoher Preis für einen kurzen Rausch.
              </p>
              <p>
                Wer an und für sich kein CBD Gras rauchen möchte, aber dennoch
                von den <strong>Vorteilen des CBDs profitieren möchte</strong>,
                probiert am besten das CBD in anderen Darreichungsformen aus.{" "}
                <strong>
                  Am häufigsten kaufen Kundinnen und Kunden das{" "}
                  <Link to="/vergleiche/cbd-oel/">CBD Öl</Link>
                </strong>
                , bei dem garantiert sichergestellt werden kann, dass es nicht
                in einem Rausch enden kann. CBD Öle gibt es in den
                verschiedensten Formen, Stärken, Geschmacksrichtungen und von
                zahlreichen Anbietern. Eine sehr{" "}
                <strong>übersichtliche und gut strukturierte Seite</strong>, die
                Leserinnen und Leser dabei hilft, vereinfacht schnell an
                Informationen zu kommen, finden Sie{" "}
                <Link to="/vergleiche/cbd-oel/">hier</Link>.
              </p>
            </Col>
            <Col md={4} xl={3} align="center">
              <Link to="/vergleiche/cbd-oel/">
                <ProductBox center>
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/naturecan.png"
                    alt="Naturecan"
                    layout="constrained"
                    placeholder="blurred"
                    width={200}
                  />
                  <ProductLabel>Zum CBD Öl Vergleich</ProductLabel>
                </ProductBox>
              </Link>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CbdLegalitaet
