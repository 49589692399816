import styled from "styled-components"

export const TableOfContents = styled.ol`
  display: inline-block;
  background: #f6f6f6;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;

  ol {
    list-style: lower-latin;
    margin-top: 5px;
  }

  li {
    margin-bottom: 5px;
  }
`
